//@flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SEO from '../../../components/seo';
import { PrivateRoute } from '../../../services/auth';

import ChapterScreen from '../../../screens/Chapter';


const ChapterPage = () => {
  const { episode1, episode2, episode3, episode4, episode5, episode6 } = useStaticQuery(
    graphql`
      query Chapter4GetImages($maxWidth: Int) {
        episode1: file(relativePath: { eq: "video/thumb9.png" }) {
          ...defaultImage
        }
        episode2: file(relativePath: { eq: "video/thumb10.png" }) {
          ...defaultImage
        },
        episode3: file(relativePath: { eq: "video/thumb11.png" }) {
          ...defaultImage
        },
        episode4: file(relativePath: { eq: "video/thumb12.png" }) {
          ...defaultImage
        },
        episode5: file(relativePath: { eq: "video/thumb13.png" }) {
          ...defaultImage
        },
        episode6: file(relativePath: { eq: "video/thumb14.png" }) {
          ...defaultImage
        }
      }
    `,
    {
      maxWidth: 420,
    }
  );

  return (
    <>
      <SEO title="Chapter" />
      <ChapterScreen
        chapterNumber={4}
        images={[episode1, episode2, episode3, episode4, episode5, episode6]}
      />
    </>
  );
};

export default PrivateRoute(ChapterPage);